import { navigate } from "gatsby"
import React, { ReactElement } from "react"

const AccountHomePage = (): ReactElement => {
  navigate("./change-password")

  return <></>
}

export default AccountHomePage
